import { Component, OnInit } from '@angular/core';
import { ContentfulService } from '../contentful.service'; 
import { Entry } from 'contentful';

@Component({
  selector: 'app-company-info',
  templateUrl: './company-info.component.html',
  styleUrls: ['./company-info.component.css']
})
export class CompanyInfoComponent implements OnInit {

  company: Entry<any>[] = [];

  constructor(private contentfulService: ContentfulService) { }

  ngOnInit() {
    this.contentfulService.getCompany()
    .then(company => this.company = company)
  }

}
