import { Injectable } from '@angular/core';
import { createClient, Entry} from 'contentful';

const CONFIG = {
  space: 'sp6vb8si03pd',
  accessToken: '052d976d06eef4e44b50ec887508f2cf99f168e9c1e3d2bbba8b1bc7de17b6fd',

  contentTypeIds: {
    product: '2PqfXUJwE8qSYKuM0U6w8M',
    contact: 'contact',
    company: 'company'
  }
}

@Injectable()
export class ContentfulService {
  private cdaClient = createClient ({
    space: CONFIG.space,
    accessToken: CONFIG.accessToken
  });

  constructor() { }

  getProducts(query?: object): Promise<Entry<any>[]> {
    return this.cdaClient.getEntries(Object.assign({
      content_type: CONFIG.contentTypeIds.product
    }, query))
    .then(res => res.items);
  }

  getContacts(query?: object): Promise<Entry<any>[]> {
    return this.cdaClient.getEntries(Object.assign({
      content_type: CONFIG.contentTypeIds.contact
    }, query))
    .then(res => res.items);
  }

  getCompany(query?: object): Promise<Entry<any>[]> {
    return this.cdaClient.getEntries(Object.assign({
      content_type: CONFIG.contentTypeIds.company  
    }, query))
    .then(res => res.items);
  }
}
